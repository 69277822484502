<template>
    <div>
        <h5 class="h5">Filtrer selon une période de dates</h5>
        <div class="row mt-3">
            <div class="col">
                <label for="start_date">Date de début</label>
                <input class="form-control" type="date"  name="start_date" id="start_date" v-model="start_date">
            </div>
            <div class="col">
                <label for="end_date">Date de fin</label>
                <input class="form-control" type="date"  name="end_date" id="end_date" v-model="end_date">
            </div>
            <div class="col align-self-end">
                <a 
                	:href="route + '?filter_by_dates=true&start_date=' + start_date + '&end_date=' + end_date" 
                	class="btn btn-outline-dark px-4 w-100" @click="onClickFilterByDate"
                	:class="[{'disabled': start_date == '' || end_date == ''}]"
            	>
                    Filtrer selon la période
                </a>
            </div>
            <div class="col align-self-end">
                <a :href="route" class="btn btn-outline-dark px-4 w-100">Réinitialiser les filtres</a>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        props: {
            route: String,
        },
        mounted: function() {

        },
        data: function() {
            return {
                start_date: '',
                end_date: '',
            };
        },
        methods: {
        	onClickFilterByDate(e){
        		if(this.start_date == '' || this.end_date == ''){
        			e.preventDefault();
        		}
        	}
        }

    };
</script>
