import { render, staticRenderFns } from "./SortModelForm.vue?vue&type=template&id=bbbdd098&"
import script from "./SortModelForm.vue?vue&type=script&lang=js&"
export * from "./SortModelForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/listederappel/liste-de-rappel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bbbdd098')) {
      api.createRecord('bbbdd098', component.options)
    } else {
      api.reload('bbbdd098', component.options)
    }
    module.hot.accept("./SortModelForm.vue?vue&type=template&id=bbbdd098&", function () {
      api.rerender('bbbdd098', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/SortModelForm.vue"
export default component.exports