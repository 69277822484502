<template>

    <div >

        <h4 v-if="worker.id">
            Modifier l'employé
        </h4>

        <h4>
            Créer un employé
        </h4>
        <div class="card mt-3">
            <div class="card-body">

                <form :action="route" method="POST">
                    <input type="hidden" name="_token" :value="csrf" />
                    <input v-if="worker.id" type="hidden" name="id" :value="worker.id">
                    <div class="row">
                        <div class="col-md-4 form-group">
                            <label for="last_name">Nom</label>
                            <input class="form-control" type="text" v-model="last_name" id="last_name" name="last_name"/>
                        </div>

                        <div class="col-md-4 form-group">
                            <label for="first_name">Prénom</label>
                            <input class="form-control" type="text" v-model="first_name" id="first_name" name="first_name"/>
                        </div>
                        <div class="col-md-4 form-group">
                            <label for="email">Courriel</label>
                            <input type="email" class="form-control" v-model="email" id="email" name="email"/>
                        </div>
                    </div>

                    <div class="row mb-3" v-for="(item, index) in phones" :key="index">
                        <div class="col">
                            <label :for="'phone_' + index">Numéro de téléphone #{{ index+1 }}</label>
                            <input class="form-control" v-model="item.number" name="phones[]" :id="'phone_' + index"/>
                        </div>

                        <div class="col align-self-end">
                            <div class="btn btn-outline-dark w-100" @click="addPhoneNumber()" v-if="index == 0">
                                <i class="bx bx-plus-circle" aria-hidden="true"></i>
                                Ajouter une autre numéro de téléphone
                            </div>
                            <div v-else>
                                <div class="btn btn-outline-secondary w-100" @click="deleteRow(index, phones)">
                                    <i class="bx bx-minus-circle" aria-hidden="true"></i>
                                    Supprimer le numéro de téléphone
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 form-group custom-form-check">
                            <label>Est-ce que l’employé à un permis de conduire valide?</label>
                            <input type="hidden" name="has_driving_licence" :value="has_driving_licence">
                            <div class="row d-flex m-0 p-0">
                                <div class="switch-field col p-0 mr-2">
                                    <input type="radio" id="no_driver_licence" value="no" checked="checked" v-model="has_driving_licence" :value="false" />
                                    <label for="no_driver_licence">Non</label>
                                </div>

                                <div class="switch-field col p-0">
                                    <input type="radio" id="has_driver_licence" value="yes" v-model="has_driving_licence" :value="true" />
                                    <label for="has_driver_licence">Oui</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 form-group" v-if="has_driving_licence">
                            <label for="driving_licence_number">Numéro du permis de conduire</label>
                            <input class="form-control" type="text" v-model="driving_licence_number" name="driving_licence_number" id="driving_licence_number"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label for="division_id">Division</label>
                            <select class="form-control" v-model="division_id" name="division_id" id="division_id">
                                <option :value="division.id" v-for="division in divisions" :key="division.id">{{ division.label }}</option>
                            </select>
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="job_name">Fonction au dossier</label>
                            <input class="form-control" type="text" v-model="job_name" name="job_name" id="job_name"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label for="job">Emploi(s) principal(aux)</label>
                            <div style="height:auto;" class="form-control">
                                <div v-for="(job, index) in jobs" >
                                    <input type="checkbox" name="principal_job[]" :value="job.key" v-model="principal_job" :id="'principal_job_' + index">
                                    <label :for="'principal_job_' + index"> {{ job.label }} </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="job">Emploi(s) secondaire(s)</label>
                            <div style="height:auto;" class="form-control">
                                <div v-for="(job, index) in jobs" :key="index" >
                                    <input type="checkbox" name="sub_jobs[]" :value="job.key" v-model="sub_jobs" :id="'sub_jobs_' + index">
                                    <label :for="'sub_jobs_' + index"> {{ job.label }} </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row ">
                        <div class="col-md-6 form-group custom-form-check">
                            <label>État de l'employé</label>
                            <input type="hidden" name="is_active" :value="is_active">
                            <div class="row d-flex m-0 p-0">
                                <div class="switch-field col p-0 mr-2">
                                    <input type="radio" id="radioactif1" name="is_active" value="no" checked="checked" v-model="is_active" :value="true" /> <label for="radioactif1">Actif</label>
                                </div>

                                <div class="switch-field col p-0">
                                    <input type="radio" id="radioactif2" name="is_active" value="yes" v-model="is_active" :value="false" /> <label for="radioactif2">Inactif</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="employment_status">Status d'employé</label>
                            <select class="form-control" v-model="employment_status" name="employment_status" id="employment_status">
                                <option :value="status.key" v-for="(status, index) in employment_status_list" :key="index">{{ status.label }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 form-group">
                            <label for="hiring_date">Date d’embauche</label>
                            <input class="form-control" type="date" v-model="hiring_date" name="hiring_date" id="hiring_date">
                        </div>
                        <div class="col-md-4 form-group">
                            <label for="end_hiring_date">Date de fin d'embauche</label>
                            <input class="form-control" type="date" v-model="end_hiring_date" name="end_hiring_date" id="end_hiring_date">
                        </div>
                        <div class="col-md-4 form-group" lang="en-US">
                            <label for="seniority">Ancienneté</label>
                            <input class="form-control" type="number" step="0.01" v-model.number="seniority" name="seniority" id="seniority">
                        </div>
                    </div>

                    <h5 class="mt-3">
                        <i class="fas fa-info-circle" aria-hidden="true"></i> Précisez les dates de vacances de l’employé (Ou autre période d’inactivité prévue)
                    </h5>



                    <div class="row mt-3" >
                        <div class="col">
                            <span class="btn btn-outline-dark" @click="addInactivityPeriod()">
                                <i class="bx bx-plus-circle" aria-hidden="true"></i>
                                Ajouter une période d'inactivité
                            </span>
                        </div>
                    </div>
                    <div v-for="(item, index) in inactivity_periods" class="row mt-3">

                        <div class="col">
                            <label :for="'inactivity_periods[' + index + '][name]'">Nom de la période d'inactivitée #{{ index+1 }}</label>
                            <input required class="form-control" type="text" v-model="item.name" :name="'inactivity_periods[' + index + '][name]'" :id="'inactivity_periods[' + index + '][name]'" />
                        </div>
                        <div class="col">
                            <label :for="'inactivity_periods[' + index + '][start_date]'">Date de début</label>
                            <input required class="form-control" type="date" v-model="item.start_date" :name="'inactivity_periods[' + index + '][start_date]'" :id="'inactivity_periods[' + index + '][start_date]'">
                        </div>
                        <div class="col">
                            <label :for="'inactivity_periods[' + index + '][end_date]'">Date de retour</label>
                            <input required class="form-control" type="date" v-model="item.end_date" :name="'inactivity_periods[' + index + '][end_date]'" :id="'inactivity_periods[' + index + '][end_date]'">
                        </div>
                        <div class="col align-self-end">
                            <div class="btn btn-outline-secondary w-100" @click="deleteRow(index, inactivity_periods)">
                                <i class="bx bx-minus-circle" aria-hidden="true"></i>
                                Supprimer
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <label for="note"> Ajouter des notes à l’employé <span class="text-muted">(Falcultatif)</span> </label>
                            <textarea class="form-control custom-textarea" v-model="note" name="note" id="note"></textarea>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-dark mt-3 px-4">
                        Soumettre
                    </button>
                </form>
            </div>
        </div>
    </div>

</template>

<script>

    export default {
        name: 'worker-form',
        props: {
            worker: '',
            route: String,
            back_route: String,
            csrf: String,
            jobs: Array,
            divisions: Array,
	        employment_status_list: Array,
            old_data: ''
        },
        mounted: function() {
            if (Object.keys(this.old_data).length > 0) {
                this.getOldValues(this.old_data);
            }

            if (Object.keys(this.worker).length > 0) {
                this.getOldValues(this.worker);
            }
        },
        data: function() {
            return {
                first_name: '',
                last_name: '',
                email: '',
                note: '',
                has_driving_licence: '',
                driving_licence_number: '',
                end_hiring_date: '',
                job_name:'',
                principal_job: [],
                sub_jobs: [],
                division_id: '',
		        employment_status: '',
                is_active: '',
                hiring_date: '',
                seniority:'',
                inactivity_periods: [],
                phones: [
                    {
                        number: ''
                    }
                ]
            };
        },
        methods: {
            deleteRow(index, model) {
                model.splice(index, 1);
            },
            addPhoneNumber() {
                this.phones.push({
                    number: ''
                });
            },
            addInactivityPeriod() {
                this.inactivity_periods.push({
                    name: '',
                    start_date: '',
                    end_date: ''
                });
            },
            getOldValues(model) {
                this.phones = [];
                Object.values(model.phones).forEach(item => {
                    this.phones.push({
                        number: item
                    });
                });

                if (model.inactivity_periods !== null) {

                    this.inactivity_periods = model.inactivity_periods;
                }

                this.first_name             = model.first_name;
                this.last_name              = model.last_name;
                this.email                  = model.email;
                this.note                   = model.note;
                this.has_driving_licence    = model.has_driving_licence;
                this.driving_licence_number = model.driving_licence_number;
                this.principal_job          = model.principal_job;
                this.sub_jobs               = model.sub_jobs;
                this.division_id            = model.division_id;
                this.is_active              = model.is_active;
                this.hiring_date            = model.hiring_date;
                this.end_hiring_date        = model.end_hiring_date;
                this.employment_status	    = model.employment_status;
                this.job_name               = model.job_name;
                this.seniority              = model.seniority;
            }
        }

    };
</script>
