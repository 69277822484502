var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "card mt-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmit($event)
              },
            },
          },
          [
            _c("input", {
              attrs: { type: "hidden", name: "_token" },
              domProps: { value: _vm.csrf },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "custom-control custom-switch" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.payload.activated,
                        expression: "payload.activated",
                      },
                    ],
                    staticClass: "custom-control-input",
                    attrs: { type: "checkbox", id: "activated" },
                    domProps: {
                      checked: Array.isArray(_vm.payload.activated)
                        ? _vm._i(_vm.payload.activated, null) > -1
                        : _vm.payload.activated,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.payload.activated,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.payload,
                                "activated",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.payload,
                                "activated",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.payload, "activated", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "custom-control-label",
                      attrs: { for: "activated" },
                    },
                    [_vm._v(_vm._s(_vm.modelActiveText))]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col form-group" }, [
                _c("label", { attrs: { for: "name" } }, [
                  _vm._v("Nom du modèle"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.payload.name,
                      expression: "payload.name",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "name", name: "name" },
                  domProps: { value: _vm.payload.name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.payload, "name", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors && _vm.errors.name
                  ? _c(
                      "div",
                      { staticClass: "mt-3 alert alert-danger alert-block" },
                      [
                        _vm._v(
                          "\n                    \t    \t" +
                            _vm._s(_vm.errors.name) +
                            "\n                    \t    "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("label", { attrs: { for: "models" } }, [
                  _vm._v("Modèles disponibles"),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list-group sort-models-available" },
                  [
                    _c(
                      "draggable",
                      {
                        attrs: { list: _vm.models.available, group: "models" },
                      },
                      _vm._l(_vm.models.available, function (model) {
                        return _c(
                          "div",
                          {
                            key: model.value,
                            staticClass: "list-group-item",
                            staticStyle: { cursor: "move" },
                          },
                          [
                            _vm._v(
                              "\n                    \t    \t\t\t" +
                                _vm._s(model.label) +
                                "\n                    \t    \t\t"
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("label", { attrs: { for: "models" } }, [
                  _vm._v("Modèles sélectionnés"),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list-group sort-models-selected" },
                  [
                    _c(
                      "draggable",
                      { attrs: { list: _vm.payload.values, group: "models" } },
                      _vm._l(_vm.payload.values, function (model) {
                        return _c(
                          "div",
                          {
                            key: model.value,
                            staticClass: "list-group-item",
                            staticStyle: { cursor: "move" },
                          },
                          [
                            _vm._v(
                              "\n\t                    \t\t\t\t" +
                                _vm._s(model.label) +
                                "\n\t                    \t\t\t"
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-dark mt-3 px-4",
                attrs: { type: "submit" },
              },
              [
                _vm._v(
                  "\n                        Soumettre\n                    "
                ),
              ]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }