var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", [_vm._v("\n        Créer une requête\n    ")]),
    _vm._v(" "),
    _vm.step_one
      ? _c("div", [
          _c("div", { staticClass: "card mt-3" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("label", { attrs: { for: "name" } }, [_vm._v("Nom")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.name,
                        expression: "name",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "name", id: "name" },
                    domProps: { value: _vm.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.name = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("label", { attrs: { for: "division_id" } }, [
                    _vm._v("Division"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.division_id,
                          expression: "division_id",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { name: "division_id", id: "division_id" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.division_id = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.divisions, function (division) {
                      return _c(
                        "option",
                        { key: division.id, domProps: { value: division.id } },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(division.label) +
                              "\n                            "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("label", { attrs: { for: "sorting" } }, [
                    _vm._v("Choisir le modèle de tri"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.sorting,
                          expression: "sorting",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { name: "sorting", id: "sorting" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.sorting = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.sort_models, function (model, id) {
                      return _c(
                        "option",
                        { key: id, domProps: { value: id } },
                        [_vm._v(_vm._s(model))]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col-md-6 form-group" }, [
                  _c("label", { attrs: { for: "start_date" } }, [
                    _vm._v("Date de début"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.start_date,
                        expression: "start_date",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "date",
                      name: "start_date",
                      id: "start_date",
                    },
                    domProps: { value: _vm.start_date },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.start_date = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-6 form-group" }, [
                  _c("label", { attrs: { for: "end_date" } }, [
                    _vm._v("Date de fin"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.end_date,
                        expression: "end_date",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "date", name: "end_date", id: "end_date" },
                    domProps: { value: _vm.end_date },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.end_date = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row row-cols-3 d-flex m-0 mt-3" },
                _vm._l(_vm.jobs, function (job) {
                  return _c("div", { key: job.id }, [
                    _c(
                      "label",
                      {
                        class:
                          _vm.jobs_claimed[job.id] !== undefined &&
                          _vm.jobs_claimed[job.id] == true
                            ? "bg-dark text-white col p-3 mb-0 mr-2 border text-left"
                            : "col p-3 mb-0 border mr-2 text-left",
                        staticStyle: { height: "100%" },
                        attrs: { for: _vm.jobs.id, role: "button" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.jobs_claimed[job.id],
                              expression: "jobs_claimed[job.id]",
                            },
                          ],
                          staticClass: "form-check-input ml-0",
                          attrs: {
                            type: "checkbox",
                            name: _vm.jobs[job.id],
                            id: job.id,
                          },
                          domProps: {
                            checked: Array.isArray(_vm.jobs_claimed[job.id])
                              ? _vm._i(_vm.jobs_claimed[job.id], null) > -1
                              : _vm.jobs_claimed[job.id],
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.jobs_claimed[job.id],
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.jobs_claimed,
                                      job.id,
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.jobs_claimed,
                                      job.id,
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.jobs_claimed, job.id, $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("small", { staticClass: "ml-4" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(job.label) +
                              "\n                            "
                          ),
                        ]),
                      ]
                    ),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mt-3" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.note,
                      expression: "note",
                    },
                  ],
                  staticClass: "form-control custom-textarea",
                  attrs: { name: "note", id: "note" },
                  domProps: { value: _vm.note },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.note = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _vm.step_one_errors.length > 0
                ? _c(
                    "div",
                    { staticClass: "mt-3 alert alert-danger alert-block" },
                    [
                      _c(
                        "ul",
                        { staticClass: "mb-0" },
                        _vm._l(_vm.step_one_errors, function (error) {
                          return _c("li", { key: error }, [
                            _vm._v(" " + _vm._s(error)),
                          ])
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-dark mt-3 px-4",
                  on: {
                    click: function ($event) {
                      return _vm.getWorkers()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Obtenir la liste\n                "
                  ),
                ]
              ),
            ]),
          ]),
        ])
      : _c("div", [
          _c("div", { staticClass: "card mt-3" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c("h5", [
                  _vm._v(
                    "\n                        Étape 2\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-primary mr-2 px-4",
                    on: {
                      click: function ($event) {
                        return _vm.refreshWorkers()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        Obtenir une nouvelle liste\n                    "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col w-100" }, [
                  _c("p", [
                    _c("strong", [_vm._v("Division :")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.getLabelById("divisions", _vm.division_id))
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col w-100" }, [
                  _c("p", [
                    _c("i", {
                      staticClass: "bx bx-calendar mr-2",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c("strong", [_vm._v("Date de début :")]),
                    _vm._v(" " + _vm._s(_vm.start_date)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col w-100" }, [
                  _c("p", [
                    _c("i", {
                      staticClass: "bx bx-calendar mr-2",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c("strong", [_vm._v("Date de fin :")]),
                    _vm._v(" " + _vm._s(_vm.end_date)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("h6", [_vm._v("Postes sélectionnés :")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row row-cols-1 row-cols-md-4 m-0" },
                _vm._l(_vm.jobs_claimed, function (job, index) {
                  return job
                    ? _c("div", { key: index, staticClass: "col p-3 border" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.getLabelById("jobs", index)) +
                            "\n                    "
                        ),
                      ])
                    : _vm._e()
                }),
                0
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "mt-3" }, [
                _c("h6", [_vm._v("Employés pouvant réaliser la requête")]),
                _vm._v(" "),
                _c(
                  "table",
                  { staticClass: "table table-striped table-hover mt-4" },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _vm._l(_vm.workers, function (worker, index) {
                      return _c("tbody", { key: index }, [
                        _c(
                          "tr",
                          {
                            class:
                              worker.ofDivision == true
                                ? "table-success"
                                : "table-light",
                          },
                          [
                            _c("td", [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    worker.last_name + " " + worker.first_name
                                  ) +
                                  "\n                                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              _vm._l(worker.jobs, function (job, index) {
                                return _c("span", { key: index }, [
                                  job.pivot.is_principal
                                    ? _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(job.label) +
                                              " (Principal)\n                                        "
                                          ),
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(job.label) +
                                            "\n                                        "
                                        ),
                                      ]),
                                  _vm._v(" "),
                                  _c("br"),
                                ])
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    worker.stats.extra_time_executed == null
                                      ? "0"
                                      : parseInt(
                                          worker.stats.extra_time_executed
                                        )
                                  ) +
                                  " heures"
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(
                                "\n                                    (" +
                                  _vm._s(
                                    worker.stats.extra_time_refused
                                      ? worker.stats.extra_time_refused
                                      : "0"
                                  ) +
                                  ")\n                                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n                                    Date d’embauche : " +
                                  _vm._s(worker.hiring_date) +
                                  "\n                                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td"),
                            _vm._v(" "),
                            _c("td", [
                              !worker.contact.show
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn btn-outline-dark mr-2 mb-2",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openWorker(index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        Contacter\n                                        "
                                      ),
                                      _c("i", {
                                        staticClass: "fas fa-angle-right ml-2",
                                      }),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn btn-outline-dark mr-2 mb-2",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        Contacter\n                                        "
                                      ),
                                      _c("i", {
                                        staticClass: "fas fa-angle-down ml-2",
                                      }),
                                    ]
                                  ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "tr",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: worker.contact.show == true,
                                expression: "worker.contact.show == true",
                              },
                            ],
                            ref: "head_worker_" + index,
                            refInFor: true,
                            staticClass: "disable-hover border",
                          },
                          [
                            _c(
                              "td",
                              { staticClass: "p-3", attrs: { colspan: "6" } },
                              [
                                _c("div", { staticClass: "row mt-1" }, [
                                  _c("div", { staticClass: "col-md-6" }, [
                                    worker.contact.timer === null
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "btn btn-dark switch-field p-3 d-flex flex-row justify-content-center align-items-center",
                                            on: {
                                              click: function ($event) {
                                                return _vm.startTimer(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-phone-volume mr-2",
                                            }),
                                            _vm._v(" Commencer l'appel "),
                                            _c("i", {
                                              staticClass:
                                                "fas fa-play-circle ml-2",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                          ]
                                        )
                                      : worker.contact.timer != null &&
                                        worker.contact.pause === true &&
                                        worker.contact.minutes < _vm.time_max
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "btn btn-outline-dark switch-field p-3 d-flex flex-row justify-content-center align-items-center",
                                            on: {
                                              click: function ($event) {
                                                return _vm.restartTimer(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-phone-volume mr-2",
                                            }),
                                            _vm._v(" Reprendre l'appel "),
                                            _c("i", {
                                              staticClass:
                                                "fas fa-play-circle ml-2",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                          ]
                                        )
                                      : worker.contact.timer != null &&
                                        worker.contact.pause === true &&
                                        worker.contact.minutes === _vm.time_max
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "btn btn-danger switch-field p-3 d-flex flex-row justify-content-center",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                Délai dépassé\n                                            "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "btn btn-dark switch-field p-3 d-flex flex-row justify-content-center align-items-center",
                                            on: {
                                              click: function ($event) {
                                                return _vm.pauseTimer(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-phone-volume mr-2",
                                            }),
                                            _vm._v(" Appel lancé "),
                                            _c("i", {
                                              staticClass:
                                                "fas fa-pause-circle ml-2",
                                            }),
                                          ]
                                        ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "p-3 border text-center mb-2",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "px-3 text-black" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-stopwatch mr-2 mt-2",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                            _vm._v(
                                              "\n                                                    " +
                                                _vm._s(worker.contact.minutes) +
                                                "\n                                                    min\n                                                    " +
                                                _vm._s(worker.contact.seconds) +
                                                "\n                                                    sec\n                                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "border-left px-3" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "far fa-calendar mt-2 mr-2",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.date) +
                                                "\n                                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "p-4" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6" },
                                      [
                                        _c(
                                          "h4",
                                          { staticClass: "pb-3 mb-4 " },
                                          [_vm._v("Informations de contact")]
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "mb-2" }, [
                                          _vm._v("Numéro(s) de téléphone :"),
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(
                                          worker.phones,
                                          function (phone, index) {
                                            return _c(
                                              "p",
                                              {
                                                key: index,
                                                staticClass: "mb-2",
                                              },
                                              [
                                                _c("strong", [
                                                  _vm._v(_vm._s(phone)),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "mb-2" }, [
                                          _vm._v("Note :"),
                                        ]),
                                        _vm._v(" "),
                                        _c("p", [
                                          _c("strong", [
                                            _vm._v(
                                              "\n                                                        " +
                                                _vm._s(worker.note) +
                                                "\n                                                    "
                                            ),
                                          ]),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-md-6 custom-radio-answers",
                                      },
                                      [
                                        _c("h4", { staticClass: "pb-3 mb-4" }, [
                                          _vm._v("Status"),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "d-flex flex-wrap" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "p-3 w-50 mb-0",
                                                class:
                                                  worker.contact.status ===
                                                  "accepted"
                                                    ? "bg-secondary text-white"
                                                    : "border",
                                                attrs: {
                                                  for:
                                                    "radio-accepted" +
                                                    worker.id,
                                                  role: "button",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                        Accepté\n                                               \t\t    "
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        worker.contact.status,
                                                      expression:
                                                        "worker.contact.status",
                                                    },
                                                  ],
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    type: "radio",
                                                    name: "status" + worker.id,
                                                    value: "accepted",
                                                    id:
                                                      "radio-accepted" +
                                                      worker.id,
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      worker.contact.status,
                                                      "accepted"
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.$set(
                                                        worker.contact,
                                                        "status",
                                                        "accepted"
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "p-3 w-50 mb-0",
                                                class:
                                                  worker.contact.status ===
                                                  "refused"
                                                    ? "bg-secondary text-white"
                                                    : "border",
                                                attrs: {
                                                  for:
                                                    "radio-refused" + worker.id,
                                                  role: "button",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                               \t\t    Refusé\n                                               \t\t    "
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        worker.contact.status,
                                                      expression:
                                                        "worker.contact.status",
                                                    },
                                                  ],
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    type: "radio",
                                                    name: "status" + worker.id,
                                                    value: "refused",
                                                    id:
                                                      "radio-refused" +
                                                      worker.id,
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      worker.contact.status,
                                                      "refused"
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.$set(
                                                        worker.contact,
                                                        "status",
                                                        "refused"
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "p-3 w-50 mb-0",
                                                class:
                                                  worker.contact.status ===
                                                  "no_answer"
                                                    ? "bg-secondary text-white"
                                                    : "border",
                                                attrs: {
                                                  for:
                                                    "radio-noanswer" +
                                                    worker.id,
                                                  role: "button",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                               \t\t    Ne répond pas\n                                               \t\t    "
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        worker.contact.status,
                                                      expression:
                                                        "worker.contact.status",
                                                    },
                                                  ],
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    type: "radio",
                                                    name: "status" + worker.id,
                                                    value: "no_answer",
                                                    id:
                                                      "radio-noanswer" +
                                                      worker.id,
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      worker.contact.status,
                                                      "no_answer"
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.$set(
                                                        worker.contact,
                                                        "status",
                                                        "no_answer"
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "p-3 w-50 mb-0",
                                                class:
                                                  worker.contact.status ===
                                                  "at_work"
                                                    ? "bg-secondary text-white"
                                                    : "border",
                                                attrs: {
                                                  for:
                                                    "radio-working" + worker.id,
                                                  role: "button",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                               \t\t    Déjà au travail\n                                               \t\t    "
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        worker.contact.status,
                                                      expression:
                                                        "worker.contact.status",
                                                    },
                                                  ],
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    type: "radio",
                                                    name: "status" + worker.id,
                                                    value: "at_work",
                                                    id:
                                                      "radio-working" +
                                                      worker.id,
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      worker.contact.status,
                                                      "at_work"
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.$set(
                                                        worker.contact,
                                                        "status",
                                                        "at_work"
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row mb-3 mt-3" }, [
                                    _c("div", { staticClass: "col-md-6" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "btn btn-dark p-3 d-flex flex-row justify-content-center",
                                          on: {
                                            click: function ($event) {
                                              return _vm.saveWorker(index)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-save mt-2 mr-2",
                                          }),
                                          _vm._v(
                                            " Enregistrer les changements\n                                                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-md-6" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "btn btn-outline-secondary p-3 d-flex flex-row justify-content-center",
                                          on: {
                                            click: function ($event) {
                                              worker.contact.show = false
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-times-circle mt-1 mr-1",
                                          }),
                                          _vm._v(
                                            " Annuler\n                                                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-dark mt-3 px-4",
                  on: {
                    click: function ($event) {
                      return _vm.post()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                        Soumettre\n                    "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h5", [_vm._v("Étape 1")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-3" }, [
      _c("h6", [
        _c("i", {
          staticClass: "bx bx-info-circle mr-2",
          attrs: { "aria-hidden": "true" },
        }),
        _vm._v(
          "\n                        Veuillez choisir tous les postes associées\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "note" } }, [
      _vm._v(" Détails ou notes supplémentaires "),
      _c("span", { staticClass: "text-muted" }, [_vm._v("(Falcultatif)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Nom, prénom")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Emploi")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("Temps supplémentaire"),
          _c("br"),
          _vm._v(" (Temps Refusé)"),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Ancienneté")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("État")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }