<template>
    <div>
        <h4>{{ title }}</h4>
        <div class="card mt-3">
            <div class="card-body">
                <form @submit.prevent="onSubmit">
                    <input type="hidden" name="_token" :value="csrf" />
					<div class="row">
						<div class="col">
							<div class="custom-control custom-switch">
							  <input type="checkbox" v-model="payload.activated"  class="custom-control-input" id="activated">
							  <label class="custom-control-label" for="activated">{{modelActiveText}}</label>
							</div>	
						</div>
					</div>
					<br>
                    <div class="row">
                    	<div class="col form-group">
                    	    <label for="name">Nom du modèle</label>
                    	    <input class="form-control" type="text" v-model="payload.name" id="name" name="name"/>
                    	    <div class="mt-3 alert alert-danger alert-block" v-if="errors && errors.name">
                    	    	{{errors.name}}
                    	    </div>
                    	</div>
                    </div>
                    <div class="row">
                    	<div class="col">
                    	    <label for="models">Modèles disponibles</label>
                    	    <ul class="list-group sort-models-available">
                    	    	<draggable
                    	    		:list="models.available"
                    	    		group="models">
                    	    		<div
                    	    			class="list-group-item"
                    	    			style="cursor: move;"
                    	    			:key="model.value"
                    	    			v-for="model in models.available">
                    	    			{{model.label}}
                    	    		</div>
                    	    	</draggable>
                    	    </ul>
                    	</div>
                    	<div class="col">
                    	    <label for="models">Modèles sélectionnés</label>
                    		<ul class="list-group sort-models-selected">
                    			<draggable
	                    			:list="payload.values"
	                    			group="models">
                    				<div
	                    				class="list-group-item"
	                    				style="cursor: move;"
	                    				:key="model.value"
	                    				v-for="model in payload.values">
	                    				{{model.label}}
	                    			</div>
                    			</draggable>
                    		</ul>
                    	</div>
                    </div>
                    <hr>
                    <button type="submit" class="btn btn-dark mt-3 px-4">
                        Soumettre
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import draggable from 'vuedraggable'

export default {
    name: 'sort-model-form',
    props: {
        csrf: String,
        route: String,
        inputs: Object,
        model: {
        	default: {}
        }
    },
    components: {
        draggable,
    },
    computed: {
    	title() {
    		return this.payload.id ? 'Modifier le modèle' : 'Créer un modèle'
    	},
    	modelActiveText() {
    		return this.payload.activated ? 'Activé' : 'Désactivé'
    	},
    	isEdit() {
    		return Boolean(this.model && this.model.id)
    	},
    },
    data() {
        return {
            payload: {},
            models: {
            	available: [],
            	values: [],
            },
            errors: {}
        };
    },
    created() {
    	this.payload = this.isEdit ? JSON.parse(JSON.stringify(this.model)) : {
        	name: '',
        	activated: true,
        	values: []
        }

    	Object.keys(this.inputs).forEach( item => {
    		let used = false;

    		if(this.model && this.model.values.length){
				this.model.values.forEach( modelValue => {
					if(modelValue.value == item){
						used = true
					}
				});
    		}

			if(!used){
				this.models.available.push({
					label: this.inputs[item],
					value: item
				})
			}
		})
    },
    methods: {
        onSubmit() {
        	if(this.payload.name.trim() == ''){
        		this.$set(this.errors, 'name', "Le nom du modèle est requis.")//adds reactivity since errors.name was not set in data()
        		return;
        	}else{
        		delete this.errors.name
        	}

            axios.post(this.route, this.payload)
	            .then((response) => {
	                window.location = response.data.route;
	            });
        },
        getModel() {
        },
    }
}
</script>
