require('./bootstrap');
import Vue from 'vue';

Vue.component('worker-form', require('./components/WorkerForm').default);
Vue.component('request-form', require('./components/RequestForm').default);
Vue.component('recall-form', require('./components/RecallForm').default);
Vue.component('sort-model-form', require('./components/SortModelForm').default);
Vue.component('filter-by-dates', require('./components/FilterByDates').default);

const app = new Vue({
    el: '#app',
});

const axios = require('axios');

const Swal = require('sweetalert2');

document.body.addEventListener("click", function (event) {

    if (event.target.classList.contains("js-btn-destroy-datas")) {
        event.preventDefault();

        let id = event.target.id;
        let path = null;
        let idToDelete = null;

        if(id == 'is_child') {
            path = event.target.parentElement.href;
            idToDelete = event.target.parentElement.dataset.id;
        } else {
            path = event.target.href;
            idToDelete = event.target.dataset.id;
        }

        Swal.fire({
            title: 'Êtes-vous sûr ?',
            text: 'Cette entrée sera supprimée definitivement !',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#343a40',
            cancelButtonColor: '#e3342f',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Confirmer'
        }).then((result) => {
            if (result.value) {

                axios.post(path, {
                    id: idToDelete,
                })
                .then(function (response) {
                    window.location.href = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            } else {
                Swal.fire('L\'entrée n\'a pas été supprimée');
            }
        });
    }
});
