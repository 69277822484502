
export default  {

    methods: {

        getKeyLabel(index, jobs) {
            let label = '';

            Object.values(jobs).forEach(item => {

                if (item.key === index) {
                    label = item.label;
                }

            });

            return label;
        },

    }
  }
