<template>
    <div>
        <h4>Liste de rappel</h4>

        <div class="d-flex justify-content-center">
            <button @click="week--;load()" class="btn btn-dark mr-3">
                    <i class="fas fa-arrow-left" aria-hidden="true"></i>
            </button>
            <h4 class="">Semaine du {{ start_date }} au {{ end_date }} {{ month }} {{ year }}</h4>
            <button @click="week++;load()" class="btn btn-dark ml-3">
                <i class="fas fa-arrow-right" aria-hidden="true"></i>
            </button>
        </div>

        <table class="table table-bordered table-hover table-striped table-responsive table-sm tableau-rappel mt-4">
            <thead class="thead-dark">
                <tr>
                    <th scope="col" width="180">
                        <small class="font-weight-bold">Poste</small>
                    </th>
                    <th scope="col">
                        <small class="font-weight-bold">Nom</small>
                    </th>
                    <th scope="col">
                        <small class="font-weight-bold">Cumulatif</small>
                    </th>
                    <th scope="col">
                        <small>Tx1.5<br />201</small>
                    </th>
                    <th scope="col">
                        <small>Tx2.0<br />202</small>
                    </th>
                    <th scope="col">
                        <small>Remise<br />Tx1.5<br />221</small>
                    </th>
                    <th scope="col">
                        <small>Remise<br />Tx2.0<br />222</small>
                    </th>
                    <th scope="col">
                        <small>Refus<br />Tx1.5<br />295</small>
                    </th>
                    <th scope="col">
                        <small>Refus<br />Tx2.0<br />298</small>
                    </th>
                    <th scope="col" width="150">
                        <small class="font-weight-bold"><i class="far fa-sticky-note mr-r"></i> Notes</small>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(worker, index) in list" :key="index">
                    <td class="tablecell-paddingtop">
                        <!-- <small class="font-weight-bold">{{ getKeyLabel(worker.principal_job, jobs) }}</small> -->
                        <small class="font-weight-bold">{{ worker.job_name }}</small>
                    </td>
                    <td class="tablecell-paddingtop">
                        <small class="font-weight-bold">{{ worker.last_name }} {{ worker.first_name }}</small>
                    </td>
                    <td class="tablecell-paddingtop">
                        <small class="font-weight-bold">{{ list[index].time.total }}</small>
                    </td>
                    <td>
                        <small>
                            <input 
                            	class="form-control recall-input" 
                            	type="number" 
                            	step="0.5" 
                            	v-model="list[index].time.code_201" 
                            	:input="setRate('code_201', index)"
                        	>
                        </small>
                    </td>
                    <td>
                        <small>
                            <input 
                            	class="form-control recall-input" 
                            	type="number" 
                            	step="0.5" 
                            	v-model="list[index].time.code_202" 
                            	:input="setRate('code_202', index)"
                        	>
                        </small>
                    </td>
                    <td>
                        <small>
                            <input 
                            	class="form-control recall-input" 
                            	type="number" 
                            	step="0.5" 
                            	v-model="list[index].time.code_221" 
                            	:input="setRate('code_221', index)"
                        	>
                        </small>
                    </td>
                    <td>
                        <small>
                            <input 
                            	class="form-control recall-input" 
                            	type="number" 
                            	step="0.5" 
                            	v-model="list[index].time.code_222" 
                            	:input="setRate('code_222', index)"
                        	>
                        </small>
                    </td>
                    <td>
                        <small>
                            <input 
                            	class="form-control recall-input" 
                            	type="number" 
                            	step="0.5" 
                            	v-model="list[index].time.code_295" 
                            	:input="setRate('code_295', index)"
                        	>
                        </small>
                    </td>
                    <td>
                        <small>
                            <input 
                            	class="form-control recall-input" 
                            	type="number" 
                            	step="0.5" 
                            	v-model="list[index].time.code_298" 
                            	:input="setRate('code_298', index)"
                        	>
                        </small>
                    </td>
                    <td>
                        <!-- <small>En vacances jusqu'au 20 janvier</small> -->
                        <textarea class="form-control recall-textarea" v-model="list[index].time.note"></textarea>
                    </td>
                </tr>

            </tbody>
        </table>

        <div class="row mt-3">
            <div class="col">
                <button @click="save()" class="btn btn-dark px-4">
                    Soumettre
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    import Mixin from '../Mixin';

    export default {
        props: {
            workers: Array,
            jobs: Array,
            divisions: Array,
            propyear: Number,
            propweek: Number
        },
        mixins: [Mixin],
        created: function() {
            this.list = this.$props.workers;
        },
        mounted: function() {
            this.getDate(this.$props.propyear, this.$props.propweek);
            this.string_date =  'Semaine du ' + this.start_date + ' au ' + this.end_date + ' ' + this.month + ' ' + this.year ;
        },
        data: function() {
            return {
                string_date: '',
                list: this.workers,
                year: this.propyear,
                week: this.propweek,
                start_date: '',
                end_date:'',
                month: '',
            };
        },
        watch: {
            week: function (week) {
                if ( week < 1) {
                    this.week = 52;
                    this.year--;
                    this.getDate(this.year, this.week);
                } else if ( week > 52) {
                    this.week = 1;
                    this.year++;
                    this.getDate(this.year, this.week);
                } else {
                    this.getDate(this.year, this.week);
                }
                this.string_date =  'Semaine du ' + this.start_date + ' au ' + this.end_date + ' ' + this.month + ' ' + this.year ;
            }
        },
        methods: {
            getDate(year, week) {
                var curr = new Date(year, 0, 1 + (week ) * 7);
                var first = curr.getDate() - curr.getDay();
                var last = first + 6;
                this.start_date = new Date(curr.setDate(first)).getDate();
                this.end_date   = new Date(curr.setDate(last)).getDate();
                this.month = curr.toLocaleString('default', { month: 'long' });
            },
            setRate(rate, index) {
                switch (rate) {

                    case 'code_201':
                        this.list[index].normal_x1_5 = this.list[index].time.code_201 * 1.5;
                        break;
                    case 'code_202':
                        this.list[index].normal_x2 = this.list[index].time.code_202 * 2;
                        break;

                    case 'code_221':
                        this.list[index].discount_x1_5 = this.list[index].time.code_221 * 1.5;
                        break;
                    case 'code_222':
                        this.list[index].discount_x2 = this.list[index].time.code_222 * 2;
                        break;

                    case 'code_295':
                        this.list[index].refused_x1_5 = this.list[index].time.code_295 * 1.5;
                        break;
                    case 'code_298':
                        this.list[index].refused_x2 = this.list[index].time.code_298 * 2;
                        break;
                }
                this.list[index].time.total = this.list[index].normal_x1_5 + this.list[index].normal_x2 + this.list[index].discount_x1_5 + this.list[index].discount_x2 + this.list[index].refused_x1_5 + this.list[index].refused_x2;
            },
            save() {
                axios.post('/recall-list/save', {
                    year: this.year,
                    week: this.week,//must be number of week in year
                    list: this.list,
                    string_date: this.string_date,
                })
                .then((response) => {
                    window.location = response.data.route;
                });
            },
            load() {
                axios.get('/recall-list/getWeek', {
		        params:{
                    year: this.year,
                    week: this.week,//must be number of week in year
		        }
                })
                .then((response) => {
				    this.list = response.data.workers
                });
            },
        }
    };
</script>
